@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
body {
  font-family: "Montserrat", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(121 23 22);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(0 21 41);
  border-radius: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

button,
input,
textarea,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker {
  border-radius: 10px !important;
  text-align: center !important;
  resize: none !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(121 23 22) !important;
}

/* Table */
.ant-table-wrapper {
  overflow: auto !important;
}

.ant-table-row {
  background: rgb(255 255 255) !important;
}

.sidebar {
  height: 100vh;
  margin-right: 24px;
}

.ant-drawer-body {
  text-align: center;
  padding: unset !important;
  background: rgb(121 23 22);
}

.ant-drawer-header {
  background: rgb(121 23 22) !important;
  border-bottom: 1px solid rgb(121 23 22) !important;
}
.ant-menu-dark {
  background: rgb(121 23 22) !important;
}

.ant-drawer-title,
.ant-drawer-close {
  color: rgb(255 255 255) !important;
}

.ant-btn-primary,
.ant-spin-dot-item {
  background: rgb(121 23 22) !important;
  border: unset !important;
}

.ant-modal-content,
.ant-modal-header,
.ant-btn,
.ant-image-mask {
  border-radius: 10px !important;
}

.ant-btn-default {
  border: unset !important;
}

.ant-drawer-header {
  padding: 20px 24px !important;
}
.ant-table-placeholder {
  background: rgb(255 255 255) !important;
}

.ant-layout-header {
  background: rgb(121 23 22) !important;
}

.ant-card-body canvas {
  height: 150px !important;
}

.ant-card-head {
  border-bottom: unset !important;
}
